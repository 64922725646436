import React, { Component, Fragment } from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Intro from './Intro/Intro';
// import About from './About/About';
// import Portfolio from './Portfolio/Portfolio';
import Experience from './Experience/Experience';
// import Contact from './Contact/Contact';

class App extends Component {
  render () {
    return (
      <Fragment>
        <Header />
        <Intro />
        {/* <About /> */}
        {/* <Portfolio /> */}
        <Experience />
        {/* <Contact /> */}
        <Footer />
      </Fragment>
    );
  }
}

export default App;
