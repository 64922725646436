import "./Header.scss";
import logo from "./bc-flat-logo-1-light.svg";

function Header () {
    return (
        <header id="page-header">
            <img src={logo} alt="Ben Coates Logo" />
            {/* <ul className="links">
                <li><a href="#intro">Intro</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a href="#expereince">Experience</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul> */}
        </header>
    );
}

export default Header;