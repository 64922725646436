import "./Intro.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import logo from './bc-main-logo-2-dark.svg';
import design from './design-vector.svg';

function Intro () {
    return (
        <section id="intro">
            <img src={logo} className="logo" alt="logo" />
            {/* <FontAwesomeIcon icon={faAngleDoubleDown} /> */}
            <img src={design} className="design-vector" alt="Design Element"/>
        </section>
    );
}

export default Intro;