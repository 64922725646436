import './Experience.scss';

function Experience () {
    return (
        <section id='experience'>
            <h1>Website Under Construction</h1>
            <p>Please check back later</p>
        </section>
    );
}

export default Experience;