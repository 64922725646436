import "./Footer.scss";

function Footer() {
    return (
        <footer>
            <p>
                <a
                className="link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
                >
                Built Using React
                </a>
                &nbsp;and&nbsp; 
                <a
                className="link"
                href="https://digitalocean.com"
                target="_blank"
                rel="noopener noreferrer"
                >
                Hosted with DigitalOcean
                </a>
            </p>
            Intro photo by <a href="https://unsplash.com/@altumcode?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">AltumCode</a> on <a href="https://unsplash.com/s/photos/code?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
            <p>&copy; 2022 Benjamin Coates. All Rights Reserved.</p>
        </footer>
    );
}

export default Footer;